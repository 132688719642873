"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LimitsCanI = exports.LimitsCanIOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.LimitsCanIOperationId = 'LimitsCanI';
/**
 * Can I perform a limited action
 *
 * @path /limits/can-i/:featureId
 * @generated
 */
exports.LimitsCanI = (0, ClientController_1.ClientControllerFactory)({
    path: '/limits/can-i/:featureId',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
