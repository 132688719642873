"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificateTemplatesListCertificateTemplates = exports.CertificateTemplatesListCertificateTemplatesOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.CertificateTemplatesListCertificateTemplatesOperationId = 'CertificateTemplatesListCertificateTemplates';
/**
 * List unarchived certificate templates
 *
 * @path /certificate-templates
 * @generated
 */
exports.CertificateTemplatesListCertificateTemplates = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificate-templates',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 403, 500],
});
