"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthIam = exports.AuthIamOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.AuthIamOperationId = 'AuthIam';
/**
 * Get summary of the authenticated user
 *
 * @path /auth/iam
 * @generated
 */
exports.AuthIam = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/iam',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
