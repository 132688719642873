"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportEducatorToggleBulkEmailing = exports.SupportEducatorToggleBulkEmailingOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.SupportEducatorToggleBulkEmailingOperationId = 'SupportEducatorToggleBulkEmailing';
/**
 * Enables or disables bulk emailing for an educator
 *
 * @path /internal/support/educator/:educatorId/toggle-bulk-emailing
 * @generated
 */
exports.SupportEducatorToggleBulkEmailing = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/support/educator/:educatorId/toggle-bulk-emailing',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 403, 500],
});
