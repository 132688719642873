"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListInvites = exports.ListInvitesOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.ListInvitesOperationId = 'ListInvites';
/**
 * List invites for current educator
 *
 * @path /users/invites
 * @generated
 */
exports.ListInvites = (0, ClientController_1.ClientControllerFactory)({
    path: '/users/invites',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
