"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificateTemplatesUpdateCertificateTemplate = exports.CertificateTemplatesUpdateCertificateTemplateOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.CertificateTemplatesUpdateCertificateTemplateOperationId = 'CertificateTemplatesUpdateCertificateTemplate';
/**
 * Update an existing certificate template
 *
 * @path /certificate-templates/:certificateId
 * @generated
 */
exports.CertificateTemplatesUpdateCertificateTemplate = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificate-templates/:certificateId',
    method: 'PATCH',
    definedStatusCodes: [204, 400, 401, 403, 404, 500],
});
