"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MigrateUser = exports.MigrateUserOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.MigrateUserOperationId = 'MigrateUser';
/**
 * Applies the new user ID for admins/students that are being migrated
 *
 * @path /users/migrate-user
 * @generated
 */
exports.MigrateUser = (0, ClientController_1.ClientControllerFactory)({
    path: '/users/migrate-user',
    method: 'POST',
    definedStatusCodes: [200, 500],
});
