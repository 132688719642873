"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkImportGetBulkImport = exports.BulkImportGetBulkImportOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.BulkImportGetBulkImportOperationId = 'BulkImportGetBulkImport';
/**
 * Get a single bulk import by id
 *
 * @path /bulk-import/:importId
 * @generated
 */
exports.BulkImportGetBulkImport = (0, ClientController_1.ClientControllerFactory)({
    path: '/bulk-import/:importId',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
