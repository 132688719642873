"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMyStudentBookingsForCourse = exports.GetMyStudentBookingsForCourseOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.GetMyStudentBookingsForCourseOperationId = 'GetMyStudentBookingsForCourse';
/**
 * Gets the current students bookings for a given course
 *
 * @path /student/me/bookings/:courseId
 * @generated
 */
exports.GetMyStudentBookingsForCourse = (0, ClientController_1.ClientControllerFactory)({
    path: '/student/me/bookings/:courseId',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
