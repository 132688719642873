"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Login = exports.LoginOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.LoginOperationId = 'Login';
/**
 * Start login flow
 *
 * @path /auth/login
 * @generated
 */
exports.Login = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/login',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
