"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrdersAndBookings = exports.OrdersAndBookingsOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.OrdersAndBookingsOperationId = 'OrdersAndBookings';
/**
 * Paginate the students orders and bookings
 *
 * @path /orders-and-bookings
 * @generated
 */
exports.OrdersAndBookings = (0, ClientController_1.ClientControllerFactory)({
    path: '/orders-and-bookings',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
