"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitialiseRequestCheckout = exports.InitialiseRequestCheckoutOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.InitialiseRequestCheckoutOperationId = 'InitialiseRequestCheckout';
/**
 * Finds or creates a new Request Checkout flow
 *
 * @path /checkout/initialise/request
 * @generated
 */
exports.InitialiseRequestCheckout = (0, ClientController_1.ClientControllerFactory)({
    path: '/checkout/initialise/request',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
