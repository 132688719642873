"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FetchRequester = void 0;
class FetchRequester {
    constructor() {
        //
    }
    request(method, url, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(url.toString(), {
                method: method.toUpperCase(),
                credentials: options === null || options === void 0 ? void 0 : options.credentials,
                headers: options === null || options === void 0 ? void 0 : options.headers,
                body,
            });
            const responseBody = yield this.decodeBody(response);
            return {
                statusCode: response.status,
                body: responseBody,
                headers: Object.fromEntries(response.headers.entries()),
            };
        });
    }
    decodeBody(response) {
        if (response.status === 204) {
            return undefined;
        }
        const jsonContentTypes = ['application/json', 'application/problem+json'];
        if (jsonContentTypes.includes(response.headers.get('Content-Type'))) {
            return response.json();
        }
        return response.text();
    }
}
exports.FetchRequester = FetchRequester;
