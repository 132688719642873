import {
    controllerMap,
    ControllerMap,
    FetchRequester,
    OperationId,
} from '@cademy-package/client-sdk';

export const CademyAPI = <TOperationId extends OperationId>(
    operationId: TOperationId
): ReturnType<ControllerMap[TOperationId]> => {
    const controller = controllerMap[operationId];

    if (!controller) {
        throw new Error(`${operationId} is not a valid operation id`);
    }

    if (!process.env.NEXT_PUBLIC_CADEMY_API_URL) {
        throw new Error('Missing NEXT_PUBLIC_CADEMY_API_URL');
    }

    return controller({
        domain: process.env.NEXT_PUBLIC_CADEMY_API_URL,
        requester: new FetchRequester(),
    }) as ReturnType<ControllerMap[TOperationId]>;
};
