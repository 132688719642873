"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaptureReviewMessage = exports.CaptureReviewMessageOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.CaptureReviewMessageOperationId = 'CaptureReviewMessage';
/**
 * Add a public and or private message to a review.
 *
 * @path /reviews/capture-review/:id/message
 * @generated
 */
exports.CaptureReviewMessage = (0, ClientController_1.ClientControllerFactory)({
    path: '/reviews/capture-review/:id/message',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
