"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListMySavedCourses = exports.ListMySavedCoursesOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.ListMySavedCoursesOperationId = 'ListMySavedCourses';
/**
 * Returns a list of courses the current student has saved
 *
 * @path /student/me/saved-courses
 * @generated
 */
exports.ListMySavedCourses = (0, ClientController_1.ClientControllerFactory)({
    path: '/student/me/saved-courses',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
