"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCoursePaymentOptions = exports.GetCoursePaymentOptionsOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.GetCoursePaymentOptionsOperationId = 'GetCoursePaymentOptions';
/**
 * Gets the payment options for a course
 *
 * @path /courses/:educatorSlug/:courseSlug/payment-options
 * @generated
 */
exports.GetCoursePaymentOptions = (0, ClientController_1.ClientControllerFactory)({
    path: '/courses/:educatorSlug/:courseSlug/payment-options',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
