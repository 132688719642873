"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBookingDetailsForCourse = exports.GetBookingDetailsForCourseOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.GetBookingDetailsForCourseOperationId = 'GetBookingDetailsForCourse';
/**
 * Gets the booking(s) and course details
 *
 * @path /bookings/:educatorSlug/:courseSlug/:occurrenceId/details
 * @generated
 */
exports.GetBookingDetailsForCourse = (0, ClientController_1.ClientControllerFactory)({
    path: '/bookings/:educatorSlug/:courseSlug/:occurrenceId/details',
    method: 'GET',
    definedStatusCodes: [200, 404, 400, 500],
});
