"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkImportListContacts = exports.BulkImportListContactsOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.BulkImportListContactsOperationId = 'BulkImportListContacts';
/**
 * List the contacts from a given bulk import
 *
 * @path /bulk-import/:importId/list-contacts
 * @generated
 */
exports.BulkImportListContacts = (0, ClientController_1.ClientControllerFactory)({
    path: '/bulk-import/:importId/list-contacts',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
