"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateClaimCode = exports.GenerateClaimCodeOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.GenerateClaimCodeOperationId = 'GenerateClaimCode';
/**
 * Generate and email an educator account claim code
 *
 * @path /admins/generate-claim-code
 * @generated
 */
exports.GenerateClaimCode = (0, ClientController_1.ClientControllerFactory)({
    path: '/admins/generate-claim-code',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
