"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EducatorSetBrandColors = exports.EducatorSetBrandColorsOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.EducatorSetBrandColorsOperationId = 'EducatorSetBrandColors';
/**
 * Sets the brand colors for an educator
 *
 * @path /educators/:educatorId/branding
 * @generated
 */
exports.EducatorSetBrandColors = (0, ClientController_1.ClientControllerFactory)({
    path: '/educators/:educatorId/branding',
    method: 'PATCH',
    definedStatusCodes: [204, 400, 401, 403, 500],
});
