"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarSyncListExternalAccountCalendars = exports.CalendarSyncListExternalAccountCalendarsOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.CalendarSyncListExternalAccountCalendarsOperationId = 'CalendarSyncListExternalAccountCalendars';
/**
 * List available external calenders for this CalendarSync account
 *
 * @path /calendar/accounts/:accountId/list-external-calendars
 * @generated
 */
exports.CalendarSyncListExternalAccountCalendars = (0, ClientController_1.ClientControllerFactory)({
    path: '/calendar/accounts/:accountId/list-external-calendars',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
