"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificatesGetOrderCompletedCertificate = exports.CertificatesGetOrderCompletedCertificateOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.CertificatesGetOrderCompletedCertificateOperationId = 'CertificatesGetOrderCompletedCertificate';
/**
 * Get a certificate for completing an On-Demand course
 *
 * @path /certificates/completion/:orderId
 * @generated
 */
exports.CertificatesGetOrderCompletedCertificate = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificates/completion/:orderId',
    method: 'GET',
    definedStatusCodes: [200, 302, 400, 401, 404, 500],
});
