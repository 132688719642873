"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscribeToMembership = exports.SubscribeToMembershipOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.SubscribeToMembershipOperationId = 'SubscribeToMembership';
/**
 * Subscribe to a membership
 *
 * @path /memberships/:membershipId/subscribe
 * @generated
 */
exports.SubscribeToMembership = (0, ClientController_1.ClientControllerFactory)({
    path: '/memberships/:membershipId/subscribe',
    method: 'POST',
    definedStatusCodes: [200, 400, 401, 500],
});
