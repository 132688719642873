"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOrderDetails = exports.GetOrderDetailsOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.GetOrderDetailsOperationId = 'GetOrderDetails';
/**
 * Get order details for a course for the authenticated user
 *
 * @path /orders/:educatorSlug/:courseSlug/details
 * @generated
 */
exports.GetOrderDetails = (0, ClientController_1.ClientControllerFactory)({
    path: '/orders/:educatorSlug/:courseSlug/details',
    method: 'GET',
    definedStatusCodes: [200, 404, 400, 500],
});
