"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logout = exports.LogoutOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.LogoutOperationId = 'Logout';
/**
 * End the current session
 *
 * @path /auth/logout
 * @generated
 */
exports.Logout = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/logout',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
