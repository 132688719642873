"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserMigrationData = exports.UserMigrationDataOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.UserMigrationDataOperationId = 'UserMigrationData';
/**
 * Gets user data to allow Cognito to create Users
 *
 * @path /users/migration-data
 * @generated
 */
exports.UserMigrationData = (0, ClientController_1.ClientControllerFactory)({
    path: '/users/migration-data',
    method: 'POST',
    definedStatusCodes: [200, 400],
});
