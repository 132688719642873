"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateCourseImport = exports.CreateCourseImportOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.CreateCourseImportOperationId = 'CreateCourseImport';
/**
 * Create a new course import record in Airtable
 *
 * @path /internal/airtable/course-imports
 * @generated
 */
exports.CreateCourseImport = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/airtable/course-imports',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
