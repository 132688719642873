"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddCourseToSavedCourses = exports.AddCourseToSavedCoursesOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.AddCourseToSavedCoursesOperationId = 'AddCourseToSavedCourses';
/**
 * Saves a course to the current students list of saved courses
 *
 * @path /student/me/saved-courses
 * @generated
 */
exports.AddCourseToSavedCourses = (0, ClientController_1.ClientControllerFactory)({
    path: '/student/me/saved-courses',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
