"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificatesListCertificatesForOrder = exports.CertificatesListCertificatesForOrderOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.CertificatesListCertificatesForOrderOperationId = 'CertificatesListCertificatesForOrder';
/**
 * Lists all certificates for an order
 *
 * @path /certificates/order/:orderId
 * @generated
 */
exports.CertificatesListCertificatesForOrder = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificates/order/:orderId',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 404, 500],
});
