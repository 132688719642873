"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarSyncListCalendars = exports.CalendarSyncListCalendarsOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.CalendarSyncListCalendarsOperationId = 'CalendarSyncListCalendars';
/**
 * List all the CalendarSync calendars for the current educator
 *
 * @path /calendar/calendars
 * @generated
 */
exports.CalendarSyncListCalendars = (0, ClientController_1.ClientControllerFactory)({
    path: '/calendar/calendars',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
