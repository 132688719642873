"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailDomainsSetDefaultSender = exports.EmailDomainsSetDefaultSenderOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.EmailDomainsSetDefaultSenderOperationId = 'EmailDomainsSetDefaultSender';
/**
 * Set the default sender for an educator
 *
 * @path /email-domains/default-sender
 * @generated
 */
exports.EmailDomainsSetDefaultSender = (0, ClientController_1.ClientControllerFactory)({
    path: '/email-domains/default-sender',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 403, 500],
});
