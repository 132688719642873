"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEducatorPaymentMethods = exports.GetEducatorPaymentMethodsOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.GetEducatorPaymentMethodsOperationId = 'GetEducatorPaymentMethods';
/**
 * Get's all of the installed payment methods for the requesting educator
 *
 * @path /educator/payment-methods
 * @generated
 */
exports.GetEducatorPaymentMethods = (0, ClientController_1.ClientControllerFactory)({
    path: '/educator/payment-methods',
    method: 'GET',
    definedStatusCodes: [200, 500],
});
