"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetLocationByID = exports.GetLocationByIDOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.GetLocationByIDOperationId = 'GetLocationByID';
/**
 * Get a location by its ID
 *
 * @path /locations/:id
 * @generated
 */
exports.GetLocationByID = (0, ClientController_1.ClientControllerFactory)({
    path: '/locations/:id',
    method: 'GET',
    definedStatusCodes: [200, 404],
});
