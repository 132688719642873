"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarSyncListAccountCalendars = exports.CalendarSyncListAccountCalendarsOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.CalendarSyncListAccountCalendarsOperationId = 'CalendarSyncListAccountCalendars';
/**
 * List synced calenders for this CalendarSync account
 *
 * @path /calendar/accounts/:accountId/calendars
 * @generated
 */
exports.CalendarSyncListAccountCalendars = (0, ClientController_1.ClientControllerFactory)({
    path: '/calendar/accounts/:accountId/calendars',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
