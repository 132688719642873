"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificateTemplatesCreateCertificateTemplate = exports.CertificateTemplatesCreateCertificateTemplateOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.CertificateTemplatesCreateCertificateTemplateOperationId = 'CertificateTemplatesCreateCertificateTemplate';
/**
 * Create a new certificate template
 *
 * @path /certificate-templates
 * @generated
 */
exports.CertificateTemplatesCreateCertificateTemplate = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificate-templates',
    method: 'POST',
    definedStatusCodes: [200, 400, 401, 403, 500],
});
