"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoConferencingListAccounts = exports.VideoConferencingListAccountsOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.VideoConferencingListAccountsOperationId = 'VideoConferencingListAccounts';
/**
 * List all the VideoConferencing accounts for the current educator
 *
 * @path /video-conferencing/accounts
 * @generated
 */
exports.VideoConferencingListAccounts = (0, ClientController_1.ClientControllerFactory)({
    path: '/video-conferencing/accounts',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
