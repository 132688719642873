"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarSyncUpdateAccount = exports.CalendarSyncUpdateAccountOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.CalendarSyncUpdateAccountOperationId = 'CalendarSyncUpdateAccount';
/**
 * Update a CalendarSync account
 *
 * @path /calendar/accounts/:accountId
 * @generated
 */
exports.CalendarSyncUpdateAccount = (0, ClientController_1.ClientControllerFactory)({
    path: '/calendar/accounts/:accountId',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
