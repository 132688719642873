"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticateSocket = exports.AuthenticateSocketOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.AuthenticateSocketOperationId = 'AuthenticateSocket';
/**
 * Get a token to authenticate a socket connection
 *
 * @path /sockets/authenticate
 * @generated
 */
exports.AuthenticateSocket = (0, ClientController_1.ClientControllerFactory)({
    path: '/sockets/authenticate',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
