"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchiveReview = exports.ArchiveReviewOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.ArchiveReviewOperationId = 'ArchiveReview';
/**
 * Archive a review
 *
 * @path /reviews/:id
 * @generated
 */
exports.ArchiveReview = (0, ClientController_1.ClientControllerFactory)({
    path: '/reviews/:id',
    method: 'DELETE',
    definedStatusCodes: [204, 400, 500],
});
