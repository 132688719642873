"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EducatorGetPlanConfigurationURL = exports.EducatorGetPlanConfigurationURLOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.EducatorGetPlanConfigurationURLOperationId = 'EducatorGetPlanConfigurationURL';
/**
 * Returns the URL the Educator should be directed to to configure the selected Plan
 *
 * @path /educators/:educatorId/plan/:planId/configuration-url
 * @generated
 */
exports.EducatorGetPlanConfigurationURL = (0, ClientController_1.ClientControllerFactory)({
    path: '/educators/:educatorId/plan/:planId/configuration-url',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 403, 500],
});
