"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClaimMagicLogin = exports.ClaimMagicLoginOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.ClaimMagicLoginOperationId = 'ClaimMagicLogin';
/**
 * Issue tokens from magic link
 *
 * @path /auth/magic-login/claim
 * @generated
 */
exports.ClaimMagicLogin = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/magic-login/claim',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
