"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.XeroGetOAuthUrl = exports.XeroGetOAuthUrlOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.XeroGetOAuthUrlOperationId = 'XeroGetOAuthUrl';
/**
 * Return the URL to redirect the user to, to start the Xero OAuth flow
 *
 * @path /apps/xero/get-oauth-url
 * @generated
 */
exports.XeroGetOAuthUrl = (0, ClientController_1.ClientControllerFactory)({
    path: '/apps/xero/get-oauth-url',
    method: 'GET',
    definedStatusCodes: [200],
});
