"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientControllerFactory = void 0;
const parseUrlParameters = (path, urlParams) => {
    const directories = path.split('/');
    const substitudedDirectories = directories.map((directory) => {
        if (directory.startsWith(':')) {
            const key = directory.replace(':', '');
            if (!urlParams[key]) {
                throw new Error(`Missing route parameter ${key}`);
            }
            return urlParams[key];
        }
        return directory;
    });
    return substitudedDirectories.join('/');
};
const queryParamsToURLSearchParams = (queryParams) => {
    const searchParamEntries = Object.entries(queryParams).reduce((searchParamEntries, [key, value]) => {
        if (value === undefined) {
            return searchParamEntries;
        }
        if (Array.isArray(value)) {
            return [
                ...searchParamEntries,
                ...value.map((arrayValue) => [key, String(arrayValue)]),
            ];
        }
        return [...searchParamEntries, [key, String(value)]];
    }, []);
    return new URLSearchParams(searchParamEntries);
};
const ClientControllerFactory = ({ path, method, definedStatusCodes, }) => {
    return ({ domain, requester, options: factoryOptions = {} }) => {
        const baseUrl = new URL(domain);
        return (input, options = {}) => {
            const defaultHeaders = {};
            const factoryHeaders = factoryOptions.headers ? factoryOptions.headers : {};
            const customHeaders = options.headers ? options.headers : {};
            const headers = Object.assign(Object.assign(Object.assign({}, defaultHeaders), factoryHeaders), customHeaders);
            const queryParams = 'queryParams' in input && input.queryParams
                ? '?' + queryParamsToURLSearchParams(input.queryParams).toString()
                : '';
            const body = 'body' in input && ['POST', 'PATCH'].includes(method)
                ? JSON.stringify(input.body)
                : undefined;
            const urlParams = ('urlParams' in input && input.urlParams ? input.urlParams : {});
            const qualifiedPath = parseUrlParameters(path, urlParams);
            const endpointUrl = new URL(`${qualifiedPath}${queryParams}`, baseUrl);
            return requester
                .request(method, endpointUrl, body, {
                headers,
                credentials: 'include',
            })
                .then((response) => {
                if (!definedStatusCodes.includes(response.statusCode)) {
                    throw new Error('Untyped status code');
                }
                return response;
            });
        };
    };
};
exports.ClientControllerFactory = ClientControllerFactory;
