"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetNewPassword = exports.SetNewPasswordOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.SetNewPasswordOperationId = 'SetNewPassword';
/**
 * Set the authenticated users password in response to a challenge
 *
 * @path /auth/user/set-password
 * @generated
 */
exports.SetNewPassword = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/user/set-password',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
