"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveAdminFromEducator = exports.RemoveAdminFromEducatorOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.RemoveAdminFromEducatorOperationId = 'RemoveAdminFromEducator';
/**
 * Removes an admin user from an educator
 *
 * @path /users/:adminId/remove-admin-from-educator
 * @generated
 */
exports.RemoveAdminFromEducator = (0, ClientController_1.ClientControllerFactory)({
    path: '/users/:adminId/remove-admin-from-educator',
    method: 'PATCH',
    definedStatusCodes: [204, 400, 401, 403, 404, 500],
});
