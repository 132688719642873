"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPublicLocations = exports.GetPublicLocationsOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.GetPublicLocationsOperationId = 'GetPublicLocations';
/**
 * An unauthenticated endpoint for public UI's to render addresses
 *
 * @path /locations/by-ids
 * @generated
 */
exports.GetPublicLocations = (0, ClientController_1.ClientControllerFactory)({
    path: '/locations/by-ids',
    method: 'POST',
    definedStatusCodes: [200],
});
