"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoursesCreateOrdersImport = exports.CoursesCreateOrdersImportOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.CoursesCreateOrdersImportOperationId = 'CoursesCreateOrdersImport';
/**
 * Create an orders import ready to be configured
 *
 * @path /courses/:courseId/import-orders
 * @generated
 */
exports.CoursesCreateOrdersImport = (0, ClientController_1.ClientControllerFactory)({
    path: '/courses/:courseId/import-orders',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
