"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarSyncReauthenticateAccount = exports.CalendarSyncReauthenticateAccountOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.CalendarSyncReauthenticateAccountOperationId = 'CalendarSyncReauthenticateAccount';
/**
 * Reauthenticate a CalendarSync account
 *
 * @path /calendar/accounts/:accountId/reauthenticate
 * @generated
 */
exports.CalendarSyncReauthenticateAccount = (0, ClientController_1.ClientControllerFactory)({
    path: '/calendar/accounts/:accountId/reauthenticate',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
