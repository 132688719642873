"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarSyncAddAccount = exports.CalendarSyncAddAccountOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.CalendarSyncAddAccountOperationId = 'CalendarSyncAddAccount';
/**
 * Add a new CalendarSync account
 *
 * @path /calendar/accounts
 * @generated
 */
exports.CalendarSyncAddAccount = (0, ClientController_1.ClientControllerFactory)({
    path: '/calendar/accounts',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
