"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarSyncAddCalendar = exports.CalendarSyncAddCalendarOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.CalendarSyncAddCalendarOperationId = 'CalendarSyncAddCalendar';
/**
 * Add external calendars as CalendarSync calender
 *
 * @path /calendar/accounts/:accountId/add-calendars
 * @generated
 */
exports.CalendarSyncAddCalendar = (0, ClientController_1.ClientControllerFactory)({
    path: '/calendar/accounts/:accountId/add-calendars',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
