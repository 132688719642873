"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClaimSsoCode = exports.ClaimSsoCodeOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.ClaimSsoCodeOperationId = 'ClaimSsoCode';
/**
 * Claim an SSO code and receive a Token Set
 *
 * @path /auth/sso/claim-code
 * @generated
 */
exports.ClaimSsoCode = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/sso/claim-code',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
