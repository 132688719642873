"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveCourseFromSavedCourses = exports.RemoveCourseFromSavedCoursesOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.RemoveCourseFromSavedCoursesOperationId = 'RemoveCourseFromSavedCourses';
/**
 * @path /student/me/saved-courses/:courseId
 * @generated
 */
exports.RemoveCourseFromSavedCourses = (0, ClientController_1.ClientControllerFactory)({
    path: '/student/me/saved-courses/:courseId',
    method: 'DELETE',
    definedStatusCodes: [204, 400, 500],
});
