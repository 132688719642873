"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListPermissions = exports.ListPermissionsOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.ListPermissionsOperationId = 'ListPermissions';
/**
 * Get all the permissions a user has
 *
 * @path /permissions/list
 * @generated
 */
exports.ListPermissions = (0, ClientController_1.ClientControllerFactory)({
    path: '/permissions/list',
    method: 'GET',
    definedStatusCodes: [200, 500],
});
