"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarSyncGetEducatorDefaultCalendar = exports.CalendarSyncGetEducatorDefaultCalendarOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.CalendarSyncGetEducatorDefaultCalendarOperationId = 'CalendarSyncGetEducatorDefaultCalendar';
/**
 * Get the default CalendarSync calendar for the current educator
 *
 * @path /calendar/calendars/educator-default
 * @generated
 */
exports.CalendarSyncGetEducatorDefaultCalendar = (0, ClientController_1.ClientControllerFactory)({
    path: '/calendar/calendars/educator-default',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
