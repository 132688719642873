"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoursesCreateBookingsImport = exports.CoursesCreateBookingsImportOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.CoursesCreateBookingsImportOperationId = 'CoursesCreateBookingsImport';
/**
 * Create a bookings import ready to be configured
 *
 * @path /courses/:courseId/import-bookings
 * @generated
 */
exports.CoursesCreateBookingsImport = (0, ClientController_1.ClientControllerFactory)({
    path: '/courses/:courseId/import-bookings',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
