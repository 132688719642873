"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoConferencingAddAccount = exports.VideoConferencingAddAccountOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.VideoConferencingAddAccountOperationId = 'VideoConferencingAddAccount';
/**
 * Add a new VideoConferencing account
 *
 * @path /video-conferencing/accounts
 * @generated
 */
exports.VideoConferencingAddAccount = (0, ClientController_1.ClientControllerFactory)({
    path: '/video-conferencing/accounts',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
