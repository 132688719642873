"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordExternalRedirect = exports.RecordExternalRedirectOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.RecordExternalRedirectOperationId = 'RecordExternalRedirect';
/**
 * Record an external redirect
 *
 * @path /analytics/record/external-redirect
 * @generated
 */
exports.RecordExternalRedirect = (0, ClientController_1.ClientControllerFactory)({
    path: '/analytics/record/external-redirect',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
