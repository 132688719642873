"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalListEducatorLimits = exports.InternalListEducatorLimitsOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.InternalListEducatorLimitsOperationId = 'InternalListEducatorLimits';
/**
 * List educator limits
 *
 * @path /internal/educator/:educatorId/limits
 * @generated
 */
exports.InternalListEducatorLimits = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/educator/:educatorId/limits',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
