"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCourseDescription = exports.GetCourseDescriptionOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.GetCourseDescriptionOperationId = 'GetCourseDescription';
/**
 * Gets the HTML Description of a course
 *
 * @path /courses/:educatorSlug/:courseSlug/description
 * @generated
 */
exports.GetCourseDescription = (0, ClientController_1.ClientControllerFactory)({
    path: '/courses/:educatorSlug/:courseSlug/description',
    method: 'GET',
    definedStatusCodes: [200, 404, 400, 500],
});
