"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalDeleteEducatorPermission = exports.InternalDeleteEducatorPermissionOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.InternalDeleteEducatorPermissionOperationId = 'InternalDeleteEducatorPermission';
/**
 * Delete an educator permission
 *
 * @path /internal/educator/:educatorId/:permissionId
 * @generated
 */
exports.InternalDeleteEducatorPermission = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/educator/:educatorId/:permissionId',
    method: 'DELETE',
    definedStatusCodes: [204, 400, 500],
});
