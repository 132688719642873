"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptBookingRequest = exports.AcceptBookingRequestOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.AcceptBookingRequestOperationId = 'AcceptBookingRequest';
/**
 * Accepts a Booking Request with optional request overrides
 *
 * @path /requests/:id/accept
 * @generated
 */
exports.AcceptBookingRequest = (0, ClientController_1.ClientControllerFactory)({
    path: '/requests/:id/accept',
    method: 'POST',
    definedStatusCodes: [200],
});
