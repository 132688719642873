"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitialiseDateBookingRequestCheckout = exports.InitialiseDateBookingRequestCheckoutOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.InitialiseDateBookingRequestCheckoutOperationId = 'InitialiseDateBookingRequestCheckout';
/**
 * Finds or creates a new date Booking Request Checkout flow
 *
 * @path /checkout/initialise/date-booking-request
 * @generated
 */
exports.InitialiseDateBookingRequestCheckout = (0, ClientController_1.ClientControllerFactory)({
    path: '/checkout/initialise/date-booking-request',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
