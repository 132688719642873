"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailDomainsRefreshEmailDomain = exports.EmailDomainsRefreshEmailDomainOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.EmailDomainsRefreshEmailDomainOperationId = 'EmailDomainsRefreshEmailDomain';
/**
 * Refresh the email domain's status
 *
 * @path /email-domains/:emailDomainName/refresh
 * @generated
 */
exports.EmailDomainsRefreshEmailDomain = (0, ClientController_1.ClientControllerFactory)({
    path: '/email-domains/:emailDomainName/refresh',
    method: 'PATCH',
    definedStatusCodes: [200, 400, 401, 403, 500],
});
