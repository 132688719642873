"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitialiseBookingApplicationCheckout = exports.InitialiseBookingApplicationCheckoutOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.InitialiseBookingApplicationCheckoutOperationId = 'InitialiseBookingApplicationCheckout';
/**
 * Finds or creates a new Booking Application Checkout flow
 *
 * @path /checkout/initialise/booking-application
 * @generated
 */
exports.InitialiseBookingApplicationCheckout = (0, ClientController_1.ClientControllerFactory)({
    path: '/checkout/initialise/booking-application',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
