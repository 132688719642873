"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScormRuntimeInitialise = exports.ScormRuntimeInitialiseOperationId = void 0;
const ClientController_1 = require("../../../../../../../../ClientController");
exports.ScormRuntimeInitialiseOperationId = 'ScormRuntimeInitialise';
/**
 * Initialise a SCORM Runtime
 *
 * @path /scorm/runtime/:entityId/:lessonId/:blockId/initialise
 * @generated
 */
exports.ScormRuntimeInitialise = (0, ClientController_1.ClientControllerFactory)({
    path: '/scorm/runtime/:entityId/:lessonId/:blockId/initialise',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
