"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEducatorCredits = exports.GetEducatorCreditsOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.GetEducatorCreditsOperationId = 'GetEducatorCredits';
/**
 * Get educator credits from Airtable
 *
 * @path /internal/airtable/educator-credits
 * @generated
 */
exports.GetEducatorCredits = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/airtable/educator-credits',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
