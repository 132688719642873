"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginViaSocial = exports.LoginViaSocialOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.LoginViaSocialOperationId = 'LoginViaSocial';
/**
 * Login via Social
 *
 * @path /auth/login/via-social
 * @generated
 */
exports.LoginViaSocial = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/login/via-social',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
