"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificatesIssueCertificate = exports.CertificatesIssueCertificateOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.CertificatesIssueCertificateOperationId = 'CertificatesIssueCertificate';
/**
 * Issue a certificate for an enrollment
 *
 * @path /certificates/issue
 * @generated
 */
exports.CertificatesIssueCertificate = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificates/issue',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 404, 500],
});
