"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoConferencingArchiveAccount = exports.VideoConferencingArchiveAccountOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.VideoConferencingArchiveAccountOperationId = 'VideoConferencingArchiveAccount';
/**
 * Archive a VideoConferencing account
 *
 * @path /video-conferencing/accounts/:accountId
 * @generated
 */
exports.VideoConferencingArchiveAccount = (0, ClientController_1.ClientControllerFactory)({
    path: '/video-conferencing/accounts/:accountId',
    method: 'DELETE',
    definedStatusCodes: [200, 400, 500],
});
