"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionsTest = exports.PermissionsTestOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.PermissionsTestOperationId = 'PermissionsTest';
/**
 * Testing the permissions service
 *
 * @path /permissions/test
 * @generated
 */
exports.PermissionsTest = (0, ClientController_1.ClientControllerFactory)({
    path: '/permissions/test',
    method: 'GET',
    definedStatusCodes: [200, 500],
});
