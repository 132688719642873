"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificateTemplatesDuplicateCertificateTemplate = exports.CertificateTemplatesDuplicateCertificateTemplateOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.CertificateTemplatesDuplicateCertificateTemplateOperationId = 'CertificateTemplatesDuplicateCertificateTemplate';
/**
 * Duplicate an existing certificate template
 *
 * @path /certificate-templates/:certificateId/duplicate
 * @generated
 */
exports.CertificateTemplatesDuplicateCertificateTemplate = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificate-templates/:certificateId/duplicate',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 403, 404, 500],
});
