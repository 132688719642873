"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetChangeContactEmailConsequences = exports.GetChangeContactEmailConsequencesOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.GetChangeContactEmailConsequencesOperationId = 'GetChangeContactEmailConsequences';
/**
 * Get the potential consequences of changing a contacts email address
 *
 * @path /contacts/:contactId/change-email/consequences
 * @generated
 */
exports.GetChangeContactEmailConsequences = (0, ClientController_1.ClientControllerFactory)({
    path: '/contacts/:contactId/change-email/consequences',
    method: 'GET',
    definedStatusCodes: [200],
});
