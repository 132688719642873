"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateUserDetails = exports.UpdateUserDetailsOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.UpdateUserDetailsOperationId = 'UpdateUserDetails';
/**
 * Update a Cademy User's details
 *
 * @path /auth/users
 * @generated
 */
exports.UpdateUserDetails = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/users',
    method: 'PUT',
    definedStatusCodes: [200, 400, 500],
});
