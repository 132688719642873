"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalListEducatorPermissions = exports.InternalListEducatorPermissionsOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.InternalListEducatorPermissionsOperationId = 'InternalListEducatorPermissions';
/**
 * List educator permissions
 *
 * @path /internal/educator/:educatorId/list-permissions
 * @generated
 */
exports.InternalListEducatorPermissions = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/educator/:educatorId/list-permissions',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
