"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificatesRevokeCertificate = exports.CertificatesRevokeCertificateOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.CertificatesRevokeCertificateOperationId = 'CertificatesRevokeCertificate';
/**
 * Revoke a certificate for an enrolment
 *
 * @path /certificates/revoke
 * @generated
 */
exports.CertificatesRevokeCertificate = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificates/revoke',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 404, 500],
});
