"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarSyncListAccounts = exports.CalendarSyncListAccountsOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.CalendarSyncListAccountsOperationId = 'CalendarSyncListAccounts';
/**
 * List all the CalendarSync accounts for the current educator
 *
 * @path /calendar/accounts
 * @generated
 */
exports.CalendarSyncListAccounts = (0, ClientController_1.ClientControllerFactory)({
    path: '/calendar/accounts',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
