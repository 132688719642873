"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCourseEnrolmentsSummary = exports.GetCourseEnrolmentsSummaryOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.GetCourseEnrolmentsSummaryOperationId = 'GetCourseEnrolmentsSummary';
/**
 * Get the logged in student's most recent booking or order ID for a specific course
 *
 * @path /custom/enrolments-summary/:courseId
 * @generated
 */
exports.GetCourseEnrolmentsSummary = (0, ClientController_1.ClientControllerFactory)({
    path: '/custom/enrolments-summary/:courseId',
    method: 'GET',
    definedStatusCodes: [200, 404],
});
