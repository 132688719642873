"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompletePasswordReset = exports.CompletePasswordResetOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.CompletePasswordResetOperationId = 'CompletePasswordReset';
/**
 * Complete the forgot password flow by providing a new password and confirmation code
 *
 * @path /auth/user/forgot-password/confirm
 * @generated
 */
exports.CompletePasswordReset = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/user/forgot-password/confirm',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
