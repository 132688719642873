"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAuthHello = exports.GetAuthHelloOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.GetAuthHelloOperationId = 'GetAuthHello';
/**
 * Debug GET endpoint for testing and health checks
 *
 * @path /auth/hello
 * @generated
 */
exports.GetAuthHello = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/hello',
    method: 'GET',
    definedStatusCodes: [200, 201, 400, 500],
});
