"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarSyncSetEducatorDefaultCalendar = exports.CalendarSyncSetEducatorDefaultCalendarOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.CalendarSyncSetEducatorDefaultCalendarOperationId = 'CalendarSyncSetEducatorDefaultCalendar';
/**
 * Set the default CalendarSync calendar for the current educator
 *
 * @path /calendar/calendars/educator-default
 * @generated
 */
exports.CalendarSyncSetEducatorDefaultCalendar = (0, ClientController_1.ClientControllerFactory)({
    path: '/calendar/calendars/educator-default',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
