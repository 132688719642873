"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificatesGetBookingAttendanceCertificate = exports.CertificatesGetBookingAttendanceCertificateOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.CertificatesGetBookingAttendanceCertificateOperationId = 'CertificatesGetBookingAttendanceCertificate';
/**
 * Get the certificate for a booking attendance
 *
 * @path /certificates/attendance/:bookingId
 * @generated
 */
exports.CertificatesGetBookingAttendanceCertificate = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificates/attendance/:bookingId',
    method: 'GET',
    definedStatusCodes: [200, 302, 400, 401, 404, 500],
});
