"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EducatorEnableWhitelabelling = exports.EducatorEnableWhitelabellingOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.EducatorEnableWhitelabellingOperationId = 'EducatorEnableWhitelabelling';
/**
 * Enable or disable white labelling
 *
 * @path /educators/:educatorId/white-labelling
 * @generated
 */
exports.EducatorEnableWhitelabelling = (0, ClientController_1.ClientControllerFactory)({
    path: '/educators/:educatorId/white-labelling',
    method: 'PATCH',
    definedStatusCodes: [204, 400, 401, 403, 500],
});
