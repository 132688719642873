"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificateTemplatesArchiveCertificateTemplate = exports.CertificateTemplatesArchiveCertificateTemplateOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.CertificateTemplatesArchiveCertificateTemplateOperationId = 'CertificateTemplatesArchiveCertificateTemplate';
/**
 * Archive certificate template
 *
 * @path /certificate-templates/:certificateId
 * @generated
 */
exports.CertificateTemplatesArchiveCertificateTemplate = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificate-templates/:certificateId',
    method: 'DELETE',
    definedStatusCodes: [204, 400, 401, 403, 500],
});
