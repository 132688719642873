"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalSetEducatorPermission = exports.InternalSetEducatorPermissionOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.InternalSetEducatorPermissionOperationId = 'InternalSetEducatorPermission';
/**
 * Set an educator permission
 *
 * @path /internal/educator/:educatorId/set-permission
 * @generated
 */
exports.InternalSetEducatorPermission = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/educator/:educatorId/set-permission',
    method: 'POST',
    definedStatusCodes: [204, 400, 500],
});
