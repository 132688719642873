"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificatesListCertificatesForBooking = exports.CertificatesListCertificatesForBookingOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.CertificatesListCertificatesForBookingOperationId = 'CertificatesListCertificatesForBooking';
/**
 * Get a list of certificates for a booking
 *
 * @path /certificates/booking/:bookingId
 * @generated
 */
exports.CertificatesListCertificatesForBooking = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificates/booking/:bookingId',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 404, 500],
});
