"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitQuizResponse = exports.SubmitQuizResponseOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.SubmitQuizResponseOperationId = 'SubmitQuizResponse';
/**
 * Saves a student's response for a quiz
 *
 * @path /quiz_responses
 * @generated
 */
exports.SubmitQuizResponse = (0, ClientController_1.ClientControllerFactory)({
    path: '/quiz_responses',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
