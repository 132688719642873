"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetSavedCourseStatusByCourseId = exports.GetSavedCourseStatusByCourseIdOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.GetSavedCourseStatusByCourseIdOperationId = 'GetSavedCourseStatusByCourseId';
/**
 * @path /student/me/saved-courses/by-course-id
 * @generated
 */
exports.GetSavedCourseStatusByCourseId = (0, ClientController_1.ClientControllerFactory)({
    path: '/student/me/saved-courses/by-course-id',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
