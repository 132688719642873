"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateMembership = exports.UpdateMembershipOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.UpdateMembershipOperationId = 'UpdateMembership';
/**
 * Update a membership
 *
 * @path /memberships/:membershipId
 * @generated
 */
exports.UpdateMembership = (0, ClientController_1.ClientControllerFactory)({
    path: '/memberships/:membershipId',
    method: 'POST',
    definedStatusCodes: [200, 400, 401, 500],
});
