"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeContactEmail = exports.ChangeContactEmailOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.ChangeContactEmailOperationId = 'ChangeContactEmail';
/**
 * Changes a contacts email address
 *
 * @path /contacts/:contactId/change-email
 * @generated
 */
exports.ChangeContactEmail = (0, ClientController_1.ClientControllerFactory)({
    path: '/contacts/:contactId/change-email',
    method: 'POST',
    definedStatusCodes: [200],
});
