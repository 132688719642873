"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaveScormDataModel = exports.SaveScormDataModelOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.SaveScormDataModelOperationId = 'SaveScormDataModel';
/**
 * Save a SCORM Data Model
 *
 * @path /scorm/runtime/:entityId/:lessonId/:blockId
 * @generated
 */
exports.SaveScormDataModel = (0, ClientController_1.ClientControllerFactory)({
    path: '/scorm/runtime/:entityId/:lessonId/:blockId',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
