"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateEducator = exports.CreateEducatorOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.CreateEducatorOperationId = 'CreateEducator';
/**
 * Creates a new Educator account
 *
 * @path /educators
 * @generated
 */
exports.CreateEducator = (0, ClientController_1.ClientControllerFactory)({
    path: '/educators',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
