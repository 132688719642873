"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPassword = exports.ForgotPasswordOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.ForgotPasswordOperationId = 'ForgotPassword';
/**
 * Start password reset flow
 *
 * @path /auth/user/forgot-password
 * @generated
 */
exports.ForgotPassword = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/user/forgot-password',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
