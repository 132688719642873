"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailDomainsListEmailDomains = exports.EmailDomainsListEmailDomainsOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.EmailDomainsListEmailDomainsOperationId = 'EmailDomainsListEmailDomains';
/**
 * List all email domains for the current Educator
 *
 * @path /email-domains
 * @generated
 */
exports.EmailDomainsListEmailDomains = (0, ClientController_1.ClientControllerFactory)({
    path: '/email-domains',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 403, 500],
});
