"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCourseImports = exports.GetCourseImportsOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.GetCourseImportsOperationId = 'GetCourseImports';
/**
 * Get course imports from Airtable
 *
 * @path /internal/airtable/course-imports
 * @generated
 */
exports.GetCourseImports = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/airtable/course-imports',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
