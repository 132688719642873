"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnsubscribeEducator = exports.UnsubscribeEducatorOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.UnsubscribeEducatorOperationId = 'UnsubscribeEducator';
/**
 * Unsubscrbe an educator from all emails
 *
 * @path /educators/:educatorId/unsubscribe
 * @generated
 */
exports.UnsubscribeEducator = (0, ClientController_1.ClientControllerFactory)({
    path: '/educators/:educatorId/unsubscribe',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
