"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitDraftQuizResponse = exports.SubmitDraftQuizResponseOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.SubmitDraftQuizResponseOperationId = 'SubmitDraftQuizResponse';
/**
 * Saves a student's draft response for a quiz
 *
 * @path /quiz_responses/draft
 * @generated
 */
exports.SubmitDraftQuizResponse = (0, ClientController_1.ClientControllerFactory)({
    path: '/quiz_responses/draft',
    method: 'POST',
    definedStatusCodes: [204, 400, 500],
});
