"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailDomainsResetDefaultSender = exports.EmailDomainsResetDefaultSenderOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.EmailDomainsResetDefaultSenderOperationId = 'EmailDomainsResetDefaultSender';
/**
 * Reset the default sender for an educator
 *
 * @path /email-domains/default-sender
 * @generated
 */
exports.EmailDomainsResetDefaultSender = (0, ClientController_1.ClientControllerFactory)({
    path: '/email-domains/default-sender',
    method: 'DELETE',
    definedStatusCodes: [204, 400, 401, 403, 500],
});
