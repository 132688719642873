"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClaimEducator = exports.ClaimEducatorOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.ClaimEducatorOperationId = 'ClaimEducator';
/**
 * Claim an educator account
 *
 * @path /admins/claim-educator
 * @generated
 */
exports.ClaimEducator = (0, ClientController_1.ClientControllerFactory)({
    path: '/admins/claim-educator',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
