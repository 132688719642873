"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificateTemplatesGetCertificateTemplate = exports.CertificateTemplatesGetCertificateTemplateOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.CertificateTemplatesGetCertificateTemplateOperationId = 'CertificateTemplatesGetCertificateTemplate';
/**
 * Get certificate template by id
 *
 * @path /certificate-templates/:certificateId
 * @generated
 */
exports.CertificateTemplatesGetCertificateTemplate = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificate-templates/:certificateId',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 403, 404, 500],
});
