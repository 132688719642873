"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoursesConfigureOrdersImport = exports.CoursesConfigureOrdersImportOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.CoursesConfigureOrdersImportOperationId = 'CoursesConfigureOrdersImport';
/**
 * Provides mappings for an order import
 *
 * @path /courses/:courseId/import-orders/:importId
 * @generated
 */
exports.CoursesConfigureOrdersImport = (0, ClientController_1.ClientControllerFactory)({
    path: '/courses/:courseId/import-orders/:importId',
    method: 'PATCH',
    definedStatusCodes: [200, 400, 500],
});
