import { useMemo } from 'react';

const baseURL = new URL(process.env.NEXT_PUBLIC_MARKETPLACE_URL || '');

export const buildMinisiteLink = (educatorSlug: string, path: string) => {
    const minisiteBaseURL = new URL(`${baseURL.protocol}//${educatorSlug}.${baseURL.host}`);
    return new URL(path, minisiteBaseURL).toString();
};

export const useMinisiteLink = (educatorSlug: string, path: string) => {
    return useMemo(() => {
        return buildMinisiteLink(educatorSlug, path);
    }, [educatorSlug, path]);
};
