"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportChangeEducatorSlug = exports.SupportChangeEducatorSlugOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.SupportChangeEducatorSlugOperationId = 'SupportChangeEducatorSlug';
/**
 * Change the slug of an educator
 *
 * @path /internal/support/change-educator-slug/:educatorId
 * @generated
 */
exports.SupportChangeEducatorSlug = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/support/change-educator-slug/:educatorId',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
