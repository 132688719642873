"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportHideEducator = exports.SupportHideEducatorOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.SupportHideEducatorOperationId = 'SupportHideEducator';
/**
 * Hide an educator from the marketplace and mark them for removal
 *
 * @path /internal/support/hide-educator
 * @generated
 */
exports.SupportHideEducator = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/support/hide-educator',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
