"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaptureReviewBreakdown = exports.CaptureReviewBreakdownOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.CaptureReviewBreakdownOperationId = 'CaptureReviewBreakdown';
/**
 * Add a review breakdown to a review.
 *
 * @path /reviews/capture-review/:id/breakdown
 * @generated
 */
exports.CaptureReviewBreakdown = (0, ClientController_1.ClientControllerFactory)({
    path: '/reviews/capture-review/:id/breakdown',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
