"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPublicEducator = exports.GetPublicEducatorOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.GetPublicEducatorOperationId = 'GetPublicEducator';
/**
 * Get a educators public record
 *
 * @path /educators/:educatorId/public
 * @generated
 */
exports.GetPublicEducator = (0, ClientController_1.ClientControllerFactory)({
    path: '/educators/:educatorId/public',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
