"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InviteAdmin = exports.InviteAdminOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.InviteAdminOperationId = 'InviteAdmin';
/**
 * Invite a new or existing user to join an educator as an admin
 *
 * @path /users/invite
 * @generated
 */
exports.InviteAdmin = (0, ClientController_1.ClientControllerFactory)({
    path: '/users/invite',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
