"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZapierMarkBookingAsPaid = exports.ZapierMarkBookingAsPaidOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.ZapierMarkBookingAsPaidOperationId = 'ZapierMarkBookingAsPaid';
/**
 * Marks a Booking as Paid, from a Zapier webhook
 *
 * @path /apps/zapier/actions/mark-booking-paid
 * @generated
 */
exports.ZapierMarkBookingAsPaid = (0, ClientController_1.ClientControllerFactory)({
    path: '/apps/zapier/actions/mark-booking-paid',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
