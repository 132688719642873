"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailDomainsGetDefaultSender = exports.EmailDomainsGetDefaultSenderOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.EmailDomainsGetDefaultSenderOperationId = 'EmailDomainsGetDefaultSender';
/**
 * Get the default sender for an educator
 *
 * @path /email-domains/default-sender
 * @generated
 */
exports.EmailDomainsGetDefaultSender = (0, ClientController_1.ClientControllerFactory)({
    path: '/email-domains/default-sender',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 403, 500],
});
