"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoConferencingReauthenticateAccount = exports.VideoConferencingReauthenticateAccountOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.VideoConferencingReauthenticateAccountOperationId = 'VideoConferencingReauthenticateAccount';
/**
 * Reauthenticate a VideoConferencing account
 *
 * @path /video-conferencing/accounts/:accountId/reauthenticate
 * @generated
 */
exports.VideoConferencingReauthenticateAccount = (0, ClientController_1.ClientControllerFactory)({
    path: '/video-conferencing/accounts/:accountId/reauthenticate',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
