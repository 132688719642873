"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefreshTokens = exports.RefreshTokensOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.RefreshTokensOperationId = 'RefreshTokens';
/**
 * Refresh authentication tokens
 *
 * @path /auth/tokens/refresh
 * @generated
 */
exports.RefreshTokens = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/tokens/refresh',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
