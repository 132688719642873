"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateUserRole = exports.UpdateUserRoleOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.UpdateUserRoleOperationId = 'UpdateUserRole';
/**
 * Update the role of a user for the current educator
 *
 * @path /users/:adminId/role
 * @generated
 */
exports.UpdateUserRole = (0, ClientController_1.ClientControllerFactory)({
    path: '/users/:adminId/role',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
