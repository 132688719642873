"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportEducatorMoveToCustomPlan = exports.SupportEducatorMoveToCustomPlanOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.SupportEducatorMoveToCustomPlanOperationId = 'SupportEducatorMoveToCustomPlan';
/**
 * Moves the educator onto the Custom plan and adds overrides for their current features/limits
 *
 * @path /internal/support/educator/:educatorId/move-to-custom-plan
 * @generated
 */
exports.SupportEducatorMoveToCustomPlan = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/support/educator/:educatorId/move-to-custom-plan',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 403, 500],
});
