"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersIam = exports.UsersIamOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.UsersIamOperationId = 'UsersIam';
/**
 * Get the auth user
 *
 * @path /users/iam
 * @generated
 */
exports.UsersIam = (0, ClientController_1.ClientControllerFactory)({
    path: '/users/iam',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
