"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerifyUser = exports.VerifyUserOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.VerifyUserOperationId = 'VerifyUser';
/**
 * Verify users email address
 *
 * @path /auth/users/verify
 * @generated
 */
exports.VerifyUser = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/users/verify',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
