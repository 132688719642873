"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutPaymentOptions = exports.CheckoutPaymentOptionsOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.CheckoutPaymentOptionsOperationId = 'CheckoutPaymentOptions';
/**
 * Gets the payment option availability for use on the checkout for an educator
 *
 * @path /checkout/payment-options
 * @generated
 */
exports.CheckoutPaymentOptions = (0, ClientController_1.ClientControllerFactory)({
    path: '/checkout/payment-options',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
