"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitialiseBookingCheckout = exports.InitialiseBookingCheckoutOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.InitialiseBookingCheckoutOperationId = 'InitialiseBookingCheckout';
/**
 * Finds or creates a new Booking Checkout flow
 *
 * @path /checkout/initialise/booking
 * @generated
 */
exports.InitialiseBookingCheckout = (0, ClientController_1.ClientControllerFactory)({
    path: '/checkout/initialise/booking',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
