"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EducatorBillingStripeWebhook = exports.EducatorBillingStripeWebhookOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.EducatorBillingStripeWebhookOperationId = 'EducatorBillingStripeWebhook';
/**
 * Handles webhooks from Stripe related to Educator billing
 *
 * @path /educator-billing/stripe/webhook
 * @generated
 */
exports.EducatorBillingStripeWebhook = (0, ClientController_1.ClientControllerFactory)({
    path: '/educator-billing/stripe/webhook',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 403, 500],
});
