"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarSyncArchiveAccount = exports.CalendarSyncArchiveAccountOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.CalendarSyncArchiveAccountOperationId = 'CalendarSyncArchiveAccount';
/**
 * Archive a CalendarSync account and related calendars
 *
 * @path /calendar/accounts/:accountId
 * @generated
 */
exports.CalendarSyncArchiveAccount = (0, ClientController_1.ClientControllerFactory)({
    path: '/calendar/accounts/:accountId',
    method: 'DELETE',
    definedStatusCodes: [200, 400, 500],
});
