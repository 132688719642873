"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalDeleteEducatorLimit = exports.InternalDeleteEducatorLimitOperationId = void 0;
const ClientController_1 = require("../../../../../../../../ClientController");
exports.InternalDeleteEducatorLimitOperationId = 'InternalDeleteEducatorLimit';
/**
 * Delete an educator's limit for a specified feature
 *
 * @path /internal/educator/:educatorId/limits/override/:featureId
 * @generated
 */
exports.InternalDeleteEducatorLimit = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/educator/:educatorId/limits/override/:featureId',
    method: 'DELETE',
    definedStatusCodes: [204, 400, 500],
});
