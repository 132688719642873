"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalOverrideEducatorLimit = exports.InternalOverrideEducatorLimitOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.InternalOverrideEducatorLimitOperationId = 'InternalOverrideEducatorLimit';
/**
 * Override an educator's limit for a specified feature
 *
 * @path /internal/educator/:educatorId/limits/override
 * @generated
 */
exports.InternalOverrideEducatorLimit = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/educator/:educatorId/limits/override',
    method: 'POST',
    definedStatusCodes: [204, 400, 500],
});
