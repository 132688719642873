"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MagicLogin = exports.MagicLoginOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.MagicLoginOperationId = 'MagicLogin';
/**
 * Send magic login link
 *
 * @path /auth/magic-login
 * @generated
 */
exports.MagicLogin = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/magic-login',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
