"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetMagicUserPassword = exports.SetMagicUserPasswordOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.SetMagicUserPasswordOperationId = 'SetMagicUserPassword';
/**
 * Set a password for a magic only user.
 *
 * @path /auth/user/set-magic-user-password
 * @generated
 */
exports.SetMagicUserPassword = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/user/set-magic-user-password',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
