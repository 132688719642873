"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoConferencingUpdateAccount = exports.VideoConferencingUpdateAccountOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.VideoConferencingUpdateAccountOperationId = 'VideoConferencingUpdateAccount';
/**
 * Update a VideoConferencing account
 *
 * @path /video-conferencing/accounts/:accountId
 * @generated
 */
exports.VideoConferencingUpdateAccount = (0, ClientController_1.ClientControllerFactory)({
    path: '/video-conferencing/accounts/:accountId',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
