"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaptureReview = exports.CaptureReviewOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.CaptureReviewOperationId = 'CaptureReview';
/**
 * Create new course or educator review
 *
 * @path /reviews/capture-review
 * @generated
 */
exports.CaptureReview = (0, ClientController_1.ClientControllerFactory)({
    path: '/reviews/capture-review',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
