"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportRemoveAdminFromEducator = exports.SupportRemoveAdminFromEducatorOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.SupportRemoveAdminFromEducatorOperationId = 'SupportRemoveAdminFromEducator';
/**
 * Removes an admin user from an educator
 *
 * @path /internal/support/admin/:adminId/:educatorId
 * @generated
 */
exports.SupportRemoveAdminFromEducator = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/support/admin/:adminId/:educatorId',
    method: 'PATCH',
    definedStatusCodes: [200, 400, 500],
});
