"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoursesConfigureBookingsImport = exports.CoursesConfigureBookingsImportOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.CoursesConfigureBookingsImportOperationId = 'CoursesConfigureBookingsImport';
/**
 * Provides mappings for a booking import
 *
 * @path /courses/:courseId/import-bookings/:importId
 * @generated
 */
exports.CoursesConfigureBookingsImport = (0, ClientController_1.ClientControllerFactory)({
    path: '/courses/:courseId/import-bookings/:importId',
    method: 'PATCH',
    definedStatusCodes: [200, 400, 500],
});
