"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarSyncArchiveCalendar = exports.CalendarSyncArchiveCalendarOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.CalendarSyncArchiveCalendarOperationId = 'CalendarSyncArchiveCalendar';
/**
 * Archive a CalendarSync calendar
 *
 * @path /calendar/accounts/:accountId/:calendarId
 * @generated
 */
exports.CalendarSyncArchiveCalendar = (0, ClientController_1.ClientControllerFactory)({
    path: '/calendar/accounts/:accountId/:calendarId',
    method: 'DELETE',
    definedStatusCodes: [200, 400, 500],
});
