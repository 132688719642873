"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeBookingStudent = exports.ChangeBookingStudentOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.ChangeBookingStudentOperationId = 'ChangeBookingStudent';
/**
 * Swap the booker or guest student on a booking
 *
 * @path /bookings/:bookingId/change-student
 * @generated
 */
exports.ChangeBookingStudent = (0, ClientController_1.ClientControllerFactory)({
    path: '/bookings/:bookingId/change-student',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
