"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEducatorPermissions = exports.ListEducatorPermissionsOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.ListEducatorPermissionsOperationId = 'ListEducatorPermissions';
/**
 * List educator permissions
 *
 * @path /educators/:educatorId/list-permissions
 * @generated
 */
exports.ListEducatorPermissions = (0, ClientController_1.ClientControllerFactory)({
    path: '/educators/:educatorId/list-permissions',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
