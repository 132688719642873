"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginWithPassword = exports.LoginWithPasswordOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.LoginWithPasswordOperationId = 'LoginWithPassword';
/**
 * Login with password
 *
 * @path /auth/login/with-password
 * @generated
 */
exports.LoginWithPassword = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/login/with-password',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
