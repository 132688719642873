"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailDomainsDeleteEmailDomain = exports.EmailDomainsDeleteEmailDomainOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.EmailDomainsDeleteEmailDomainOperationId = 'EmailDomainsDeleteEmailDomain';
/**
 * Delete an email domain from the system
 *
 * @path /email-domains/:emailDomainName
 * @generated
 */
exports.EmailDomainsDeleteEmailDomain = (0, ClientController_1.ClientControllerFactory)({
    path: '/email-domains/:emailDomainName',
    method: 'DELETE',
    definedStatusCodes: [204, 400, 401, 403, 500],
});
