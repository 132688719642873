"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportEducatorResetPlan = exports.SupportEducatorResetPlanOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.SupportEducatorResetPlanOperationId = 'SupportEducatorResetPlan';
/**
 * Resets the educators plan to Free and removes any overrides for features and limits
 *
 * @path /internal/support/educator/:educatorId/reset-plan
 * @generated
 */
exports.SupportEducatorResetPlan = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/support/educator/:educatorId/reset-plan',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 403, 500],
});
