"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchCourses = exports.SearchCoursesOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.SearchCoursesOperationId = 'SearchCourses';
/**
 * Searches for courses matching a given search term or filters
 *
 * @path /search-courses
 * @generated
 */
exports.SearchCourses = (0, ClientController_1.ClientControllerFactory)({
    path: '/search-courses',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
