"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailDomainsCreateEmailDomain = exports.EmailDomainsCreateEmailDomainOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.EmailDomainsCreateEmailDomainOperationId = 'EmailDomainsCreateEmailDomain';
/**
 * Create a new email domain to verify
 *
 * @path /email-domains
 * @generated
 */
exports.EmailDomainsCreateEmailDomain = (0, ClientController_1.ClientControllerFactory)({
    path: '/email-domains',
    method: 'POST',
    definedStatusCodes: [200, 400, 401, 403, 500],
});
