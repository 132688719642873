"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitialiseGroupBookingRequestCheckout = exports.InitialiseGroupBookingRequestCheckoutOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.InitialiseGroupBookingRequestCheckoutOperationId = 'InitialiseGroupBookingRequestCheckout';
/**
 * Finds or creates a new group Booking Request Checkout flow
 *
 * @path /checkout/initialise/group-booking-request
 * @generated
 */
exports.InitialiseGroupBookingRequestCheckout = (0, ClientController_1.ClientControllerFactory)({
    path: '/checkout/initialise/group-booking-request',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
