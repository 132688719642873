"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.XeroCompleteConnection = exports.XeroCompleteConnectionOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.XeroCompleteConnectionOperationId = 'XeroCompleteConnection';
/**
 * Complete the Xero OAuth connection flow
 *
 * @path /apps/xero/complete-connection
 * @generated
 */
exports.XeroCompleteConnection = (0, ClientController_1.ClientControllerFactory)({
    path: '/apps/xero/complete-connection',
    method: 'POST',
    definedStatusCodes: [200],
});
