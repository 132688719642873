"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeOrderStudent = exports.ChangeOrderStudentOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.ChangeOrderStudentOperationId = 'ChangeOrderStudent';
/**
 * Swap the booker on an order
 *
 * @path /orders/:orderId/change-student
 * @generated
 */
exports.ChangeOrderStudent = (0, ClientController_1.ClientControllerFactory)({
    path: '/orders/:orderId/change-student',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
