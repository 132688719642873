"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangePassword = exports.ChangePasswordOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.ChangePasswordOperationId = 'ChangePassword';
/**
 * Change the authenticated users password
 *
 * @path /auth/user/change-password
 * @generated
 */
exports.ChangePassword = (0, ClientController_1.ClientControllerFactory)({
    path: '/auth/user/change-password',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
