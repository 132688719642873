"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListPublicEducatorReviews = exports.ListPublicEducatorReviewsOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.ListPublicEducatorReviewsOperationId = 'ListPublicEducatorReviews';
/**
 * Get all educator reviews
 *
 * @path /reviews/list/:educator_slug
 * @generated
 */
exports.ListPublicEducatorReviews = (0, ClientController_1.ClientControllerFactory)({
    path: '/reviews/list/:educator_slug',
    method: 'GET',
    definedStatusCodes: [200, 400],
});
