"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateMembership = exports.CreateMembershipOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.CreateMembershipOperationId = 'CreateMembership';
/**
 * Create a membership
 *
 * @path /memberships
 * @generated
 */
exports.CreateMembership = (0, ClientController_1.ClientControllerFactory)({
    path: '/memberships',
    method: 'POST',
    definedStatusCodes: [200, 400, 401, 500],
});
