"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetSuggestedCategories = exports.GetSuggestedCategoriesOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.GetSuggestedCategoriesOperationId = 'GetSuggestedCategories';
/**
 * Returns some suggested tags and categories for a course
 *
 * @path /categories/suggested
 * @generated
 */
exports.GetSuggestedCategories = (0, ClientController_1.ClientControllerFactory)({
    path: '/categories/suggested',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
