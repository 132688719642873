"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitCheckoutResponse = exports.SubmitCheckoutResponseOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.SubmitCheckoutResponseOperationId = 'SubmitCheckoutResponse';
/**
 * Handle a Checkout Response being submitted.
 *
 * @path /checkout/:checkoutId/submit-response
 * @generated
 */
exports.SubmitCheckoutResponse = (0, ClientController_1.ClientControllerFactory)({
    path: '/checkout/:checkoutId/submit-response',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
