"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchOccupations = exports.SearchOccupationsOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.SearchOccupationsOperationId = 'SearchOccupations';
/**
 * Search for an occupation
 *
 * @path /search/occupations
 * @generated
 */
exports.SearchOccupations = (0, ClientController_1.ClientControllerFactory)({
    path: '/search/occupations',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
