"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportRemoveFromSuppressionList = exports.SupportRemoveFromSuppressionListOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.SupportRemoveFromSuppressionListOperationId = 'SupportRemoveFromSuppressionList';
/**
 * Attempt to remove an email address from the suppression list
 *
 * @path /internal/support/suppression-list-removal
 * @generated
 */
exports.SupportRemoveFromSuppressionList = (0, ClientController_1.ClientControllerFactory)({
    path: '/internal/support/suppression-list-removal',
    method: 'POST',
    definedStatusCodes: [204, 404, 400, 500],
});
